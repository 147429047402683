<template>
  <div class="mod-room">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input v-model="searchForm.name" placeholder="房间名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()"> 查询 </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border style="width: 100%">
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="房间名"
      />
      <el-table-column
        prop="coverImg"
        header-align="center"
        align="center"
        label="封面图片"
      >
        <template slot-scope="scope">
          <img
            style="height: 50%; width: 50%"
            :src="scope.row.coverImg"
            @click="openImg(scope.row.coverImg)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="shareImg"
        header-align="center"
        align="center"
        label="分享图"
      >
        <template slot-scope="scope">
          <img
            style="height: 50%; width: 50%"
            :src="scope.row.shareImg"
            @click="openImg(scope.row.shareImg)"
          />
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        prop="startTime"
        header-align="center"
        align="center"
        label="直播计划开始时间"
      >
        <template slot-scope="scope">
          {{ transDate(scope.row.startTime, 'yyyy-MM-dd hh:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        prop="endTime"
        header-align="center"
        align="center"
        label="直播计划结束时间"
      >
        <template slot-scope="scope">
          {{ transDate(scope.row.endTime, 'yyyy-MM-dd hh:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        prop="anchorName"
        header-align="center"
        align="center"
        label="主播名"
      />
      <el-table-column
        prop="liveStatus"
        header-align="center"
        align="center"
        label="直播状态"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.liveStatus === 101"
            size="small"
            type="success"
          >
            直播中
          </el-tag>
          <el-tag
            v-else-if="scope.row.liveStatus === 102"
            size="small"
            type="info"
          >
            未开始
          </el-tag>
          <el-tag v-else-if="scope.row.liveStatus === 103" size="small">
            已结束
          </el-tag>
          <el-tag
            v-else-if="scope.row.liveStatus === 104"
            size="danger"
            type="info"
          >
            禁播
          </el-tag>
          <el-tag
            v-else-if="scope.row.liveStatus === 105"
            size="danger"
            type="warning"
          >
            暂停中
          </el-tag>
          <el-tag
            v-else-if="scope.row.liveStatus === 106"
            size="danger"
            type="warning"
          >
            异常
          </el-tag>
          <el-tag
            v-else-if="scope.row.liveStatus === 107"
            size="small"
            type="warning"
          >
            已过期
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="getGoodsDataList(scope.row.roomid)"
          >
            商品
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="getGoodsMediaList(scope.row.roomid)"
          >
            回放
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <el-dialog
      title="直播间商品"
      :close-on-click-modal="false"
      :visible.sync="visibleGoods"
    >
      <el-table :data="goodsData" stripe style="width: 100%">
        <el-table-column prop="name" label="商品名" width="180" />
        <el-table-column prop="url" label="商品url" width="150" />
        <el-table-column
          prop="coverImg"
          header-align="center"
          align="center"
          label="封面图片"
        >
          <template slot-scope="scope">
            <img
              style="height: 50%; width: 50%"
              :src="scope.row.coverImg"
              @click="openImg(scope.row.coverImg)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="price" label="商品价格（元）" width="150">
          <template slot-scope="scope">
            {{ scope.row.price / 100 }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleGoods = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看"
      :close-on-click-modal="false"
      :visible.sync="visibleMedis"
    >
      <el-table :data="mediaData" stripe style="width: 100%">
        <el-table-column prop="url" label="回放视频" width="150" />
        <el-table-column prop="expireTime" label="过期时间" />
        <el-table-column prop="createTime" label="创建时间" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleMedis = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        name: '',
      },
      visibleGoods: false,
      visibleMedis: false,
      dataList: [],
      goodsData: [],
      mediaData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/mall/room/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    getGoodsMediaList(roomid) {
      this.visibleMedis = true;
      this.$http({
        url: '/mall/roommedia/queryAll',
        method: 'get',
        params: {
          roomid: roomid,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.mediaData = data.list;
        } else {
          this.mediaData = [];
        }
      });
    },
    // 获取数据列表
    getGoodsDataList(roomid) {
      this.visibleGoods = true;
      this.$http({
        url: '/mall/roomgoods/queryAll',
        method: 'get',
        params: {
          roomid: roomid,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.goodsData = data.list;
        } else {
          this.goodsData = [];
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>
